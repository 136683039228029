import { useEffect, useState, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import useRefresh from './useRefresh';
import { useCryptoGirlFriend } from './useContract';

export const useTotalSupply = (forceUpdate) => {
  const [totalSupply, setTotalSupply] = useState(0);
  // const { account, chainId } = useWeb3React();
  const { fastRefresh } = useRefresh();
  const cryptoGirlFriendContract = useCryptoGirlFriend();

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {
        const [_totalSupply] = await Promise.all([
          cryptoGirlFriendContract.methods.totalSupply().call(),
        ]);

        setTotalSupply(_totalSupply);
      } catch (e) {
        console.error('Fetching global data had error', e);
      }
    };

    if (cryptoGirlFriendContract) {
      fetchGlobalData();
    }
  }, [cryptoGirlFriendContract, fastRefresh, forceUpdate]);

  return {
    totalSupply
  };
};

export const useGlobalData = (forceUpdate) => {
  const [mintPrice, setMintPrice] = useState(new BigNumber(0));
  const [maxToMint, setMaxToMint] = useState(0);
  const [saleIsActive, setSaleIsActive] = useState(new BigNumber(0));

  const cryptoGirlFriendContract = useCryptoGirlFriend();

  useEffect(() => {
    const fetch = async () => {
      try {
        const [_mintPrice, _maxToMint, _saleIsActive] = await Promise.all([
          cryptoGirlFriendContract.methods.mintPrice().call(),
          cryptoGirlFriendContract.methods.maxToMint().call(),
          cryptoGirlFriendContract.methods.saleIsActive().call()
        ]);

        setMintPrice(new BigNumber(_mintPrice));
        setMaxToMint(_maxToMint);
        setSaleIsActive(_saleIsActive);
      } catch (e) {
        console.error('Fetching global data had error', e);
      }
    };

    if (cryptoGirlFriendContract) {
      fetch();
    }
  }, [cryptoGirlFriendContract, forceUpdate]);

  return {
    mintPrice,
    maxToMint,
    saleIsActive
  };
};

export const useMint = (mintPrice) => {
  const [pending, setPending] = useState(false);
  const { account } = useWeb3React();
  const contract = useCryptoGirlFriend();

  const onMint = useCallback(async (amount) => {
    try {
      setPending(true);
      const tx = await contract.methods
        .mint(amount)
        .send({
          from: account,
          value: mintPrice.times(amount).toString(10)
        });
      setPending(false);
      return tx;
    } catch (e) {
      console.log('Mint had error :>> ', e);
      setPending(false);
      return false;
    }
  }, [account, contract, mintPrice]);

  return { onMint, pending };
};
