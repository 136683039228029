import React from 'react';
import closeIcon from '../../assets/img/ham-menu-closed.svg'
import metaMask from '../../assets/img/metamask.png'
import walletConnect from '../../assets/img/wallet-connect.png'

import { NotificationManager } from 'react-notifications';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { setupNetwork } from '../../utils/addRPC';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import { ConnectorNames, connectorsByName, connectorLocalStorageKey } from '../../utils/connectors';

function ConnectModal({ setIsModalOpen }) {
  const { activate } = useWeb3React();

  const handleConnect = (type) => {
    const connector = connectorsByName[type];
    try {
      activate(connector, async (error) => {
        if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork();
          if (hasSetup) {
            activate(connector);
          }
        } else {
          window.localStorage.removeItem(connectorLocalStorageKey);
          if (error instanceof NoEthereumProviderError) {
            NotificationManager.warning('Provider Error', 'No provider was found');
          } else if (
            error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect
          ) {
            if (connector instanceof WalletConnectConnector) {
              const walletConnector = connector;
              walletConnector.walletConnectProvider = null;
            }
            NotificationManager.warning(
              'Authorization Error',
              'Please authorize to access your account',
            );
          } else {
            NotificationManager.warning(error.name, error.message);
          }
        }
      });
    } catch (error) {
      NotificationManager.warning('Something went wrong while connect wallet');
    }
  };

  return (
    <div
      style={{
        background: 'rgba(157, 128, 128, 0.8)',
        zIndex: 90
      }}
      className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center overflow-y-auto"
    >
      <div style={{ background: '#FFE7E7', borderRadius: 32 }} className="relative p-10 w-4/5 max-w-screen-lg">
        <div className="mb-10">
          <p className="font-coiny text-xl md:texl-2xl lg:text-3xl xl:text-5xl pink-stroke-text text-center">Select a wallet provider</p>
          <img onClick={() => setIsModalOpen(false)} className="absolute cursor-pointer top-7 right-4 md:top-7 md:right-7 w-8 lg:w-12 lg:h-12 xl:w-16 xl:h-16" src={closeIcon} alt="" />
        </div>
        <div className="flex flex-col md:flex-row">
          <div
            style={{ background: '#FFD8DA', borderRadius: 32 }}
            className="p-5 xl:p-10 ml-0 md:ml-5 w-full md:w-1/2 mb-5 md:mb-0 flex flex-col items-center justify-center cursor-pointer"
            onClick={() => handleConnect(ConnectorNames['Injected'])}
          >
            <img className="w-2/12 md:w-2/5" src={metaMask} alt="" />
            <p className="font-coiny text-xl lg:text-2xl xl:text-3xl text-cg-pink-normal">Metamask</p>
            <p className="font-nunito text-sm md:text-xl xl:text-2xl mt-4 text-center">Connect to your Metamask Wallet</p>
          </div>
          <div
            style={{ background: '#FFD8DA', borderRadius: 32 }}
            className="p-5 xl:p-10 ml-0 md:ml-5 w-full md:w-1/2 mb-5 md:mb-0 flex flex-col items-center justify-center cursor-pointer"
            onClick={() => handleConnect(ConnectorNames['WalletConnect'])}
          >
            <img className="w-2/12 md:w-2/5" src={walletConnect} alt="" />
            <p className="font-coiny text-xl lg:text-2xl xl:text-3xl text-cg-pink-normal">Wallet Connect</p>
            <p className="font-nunito text-sm md:text-xl xl:text-2xl mt-4 text-center">Scan QR code to connect</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectModal;