import React from 'react';
import InView from 'react-intersection-observer';
import {  ReactComponent as HeartIcon } from '../../../assets/img/heart.svg' 
function FaqSection({setCurrentSection}) {
    let questions = [ 
        {
            qn: "What are CryptoGirlfriend NFTs?",
            ans: "The CryptoGirlfriend NFT project is a collection of 1269 uniquely designed beautiful CryptoGirlfriends residing on the Binance Smart Chain. These art pieces were handpicked by CryptoGirlfriend herself and is the very first piece of the CryptoGirlfriend NFT project which will later include a CryptoGirlfriend Comic Book."
        },
        {
            qn: "How do I buy a CryptoGirlfriend NFT and how much are they?",
            ans: "You will be able to mint a CryptoGirlfriend NFT directly on our website at cryptogirfriend.nft. Buying a Cryptogirlfriend will cost 0.2 BNB."
        },
        {
            qn: "How can I view my CryptoGirlfriend NFTs?",
            ans: "Your CryptoGirlfriend NFT can be imported into your wallet or later on one of the BSC NFT Marketplaces."
        },
        {
            qn: "How do I partner with you?",
            ans: "Please reach out to us on ways to partner with you and your project. This can include being listed on our rarity service or to schedule a text, voice or video AMA with CryptoGirlfriend herself or even assistance with launching your own artowrk or NFT project."
        },
        {
            qn: "How can I play the mini games?",
            ans: "Once all of the CryptoGirlfriend NFTs have been minted, we will be developing our first mini game. The game will be available to play cryptogirlfriend.art and you will be able to stake your girlfriends to earn other rare collectibles."
        },
        {
            qn: "Where can I see the roadmap?",
            ans: "Visit our website at cryptogirlfriend.art to see our roadmap and the progress we have made. We have a lot of cool things planned in the near future for NFT holders!"
        }
    ]
    let handeleInview = (inView, entry)=>{
        if(inView) setCurrentSection('faq')
        // else setCurrentSection(null)
    }
    return (
        <InView onChange={handeleInview}>
            <section id="faq" className="flex flex-col items-center -mt-3 p-3 md:p-10 pt-24 faq-section">
                <h1 className="font-coiny text-center text-6xl pink-stroke-text mb-10">FAQ</h1>
                {
                    questions.map((qn,id)=>{
                        if(id%2===0){
                            return <div key={id} style={{ background: "#FFE7E7", borderRadius: 55 }} className="my-5 w-full md:w-11/12 max-w-5xl p-3 xl:p-5">
                                <div  style={{ borderRadius: 55 }} className="relative p-8 border-dashed border-2 xl:border-4 text-center font-nunito border-cg-pink-normal">
                                    <p className="text-cg-pink-normal text-base md:text-xl xl:text-3xl font-black mb-5">{qn.qn}</p>
                                    <p className="p-5 xl:px-10 py-0 text-black text-sm md:text-lg xl:text-3xl font-bold">{qn.ans}</p>
                                    <HeartIcon className="absolute w-5 xl:w-10 left-2 xl:left-5 top-1/2 transform -translate-y-1/2 text-cg-pink-normal" />
                                    <HeartIcon className="absolute w-5 xl:w-10 right-2 xl:rigth-5 top-1/2 transform -translate-y-1/2 text-cg-pink-normal" />
                                </div>
                            </div>
                        }else{
                            return <div key={id} style={{ background: "#E7F5FF", borderRadius: 55 }} className="my-5 w-full md:w-11/12 max-w-5xl p-3 xl:p-5">
                                <div style={{ borderRadius: 55 }} className="relative p-8 border-dashed border-2 xl:border-4 border-cg-blue-normal text-center font-nunito text-cg-blue-normal">
                                    <p className="text-sm md:text-xl xl:text-3xl font-black mb-5">{qn.qn}</p>
                                    <p className="p-5 xl:px-10 py-0 text-black text-sm md:text-lg xl:text-3xl font-bold">{qn.ans}</p>
                                    <HeartIcon className="absolute left-5 top-1/2 transform -translate-y-1/2 text-cg-blue-normal" />
                                    <HeartIcon className="absolute right-5 top-1/2 transform -translate-y-1/2 text-cg-blue-normal" />
                                </div>
                            </div>
                        }
                    })
                }
            </section>
        </InView>
    );
}

export default FaqSection;