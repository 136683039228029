import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import useRefresh from './useRefresh';
import useWeb3 from './useWeb3';

export const useEthBalance = (forceUpdate) => {
  const web3 = useWeb3();
  const { account, chainId } = useWeb3React();
  const { fastRefresh } = useRefresh();
  const [ethBalance, setEthBalance] = useState(new BigNumber(0));

  useEffect(() => {
    async function checkBalance() {
      if (account) {
        const balance = new BigNumber(await web3.eth.getBalance(account));
        setEthBalance(balance);
      } else {
        setEthBalance(new BigNumber(0));
      }
    }

    checkBalance();
  }, [web3, account, chainId, fastRefresh, forceUpdate]);

  return ethBalance;
};
