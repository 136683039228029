import React from 'react';
import GfBubbleAnimation from "./GfBubbleAnimation"
import footerDiscord from '../../../../assets/img/footer-discord.svg'
import footerInsta from '../../../../assets/img/footer-Insta.svg'
// import footerOpensea from'../../../../assets/img/footer-opensea.svg'
import footerTwitter from '../../../../assets/img/footer-twitter.svg'
import footerYoutube from '../../../../assets/img/footer-youtube.svg'
import footerTelegram from '../../../../assets/img/footer-telegram.svg'

function index(props) {
    let socialLinks = [
        { imageUrl: footerDiscord, link: "https://discord.gg/yJfVrrQ9qn" },
        { imageUrl: footerTelegram, link: "https://t.me/cryptogirlfriend" },
        { imageUrl: footerTwitter, link: "https://twitter.com/CryptoGrlfriend" },
        { imageUrl: footerInsta, link: "https://www.instagram.com/cryptogrlfriend/" },
        // { imageUrl: footerOpensea, link: "https://opensea.io/collection/cryptogirlfriend" },
        { imageUrl: footerYoutube, link: "https://www.youtube.com/c/CryptoGirlfriend" },
    ]
    return (
        <section className="relative flex flex-col items-center min-h-screen hero-section overflow-hidden">
            <div className="mt-16 sm:mt-20 hidden sm:block md:mt-24 xl:mt-24 z-10">
                {/* <h1 className="font-coiny text-2xl lg:text-4xl xl:text-6xl pink-stroke-text text-center">Crypto Girlfriend</h1>
                <p 
                    style={{ color: '#FB8D99', letterSpacing: '0.295em' }} 
                    className="text-center font-coiny text-xl lg:text-2xl xl:text-5xl mt-2 lg:mt-3 xl:mt-6"
                >
                    OWN. HODL. EARN.
                </p> */}
            </div>
            <div className="flex flex-col absolute bottom-0 right-3 z-10">
                {
                    socialLinks.map((link, id) => {
                        return <a key={id} href={link.link} target="_blank" rel="noreferrer" ><img className="w-8 mb-3 cursor-pointer" src={link.imageUrl} alt="" /></a>
                    })
                }
            </div>
            <GfBubbleAnimation />
        </section>
    );
}

export default index;