import React from 'react';
import joinUsIcon from '../../assets/img/join-us.png'
import footerDiscord from '../../assets/img/footer-discord.svg'
import footerInsta from '../../assets/img/footer-Insta.svg'
// import footerOpensea from'../../assets/img/footer-opensea.svg'
import footerTwitter from '../../assets/img/footer-twitter.svg'
import footerYoutube from '../../assets/img/footer-youtube.svg'
import footerTelegram from '../../assets/img/footer-telegram.svg'


function Footer(props) {
    let footerLinks = [
        { imageUrl: footerDiscord, link: "https://discord.gg/yJfVrrQ9qn" },
        { imageUrl: footerTelegram, link: "https://t.me/cryptogirlfriend" },
        { imageUrl: footerTwitter, link: "https://twitter.com/CryptoGrlfriend" },
        { imageUrl: footerInsta, link: "https://www.instagram.com/cryptogrlfriend/" },
        // { imageUrl: footerOpensea, link: "https://opensea.io/collection/cryptogirlfriend" },
        { imageUrl: footerYoutube, link: "https://www.youtube.com/c/CryptoGirlfriend" },
    ]
    return (
        <footer style={{ background: "#FFE7E7" }} className="p-7 py-8 md:py-16 flex flex-col items-center">
            <img className="w-44 mb-4 lg:w-56 lg:mb-5 xl:w-96 xl:mb-6" src={joinUsIcon} alt="" />
            <div className="flex">
                {
                    footerLinks.map((link, id) => {
                        return <a key={id} target="_blank" rel="noreferrer" href={link.link}><img className="w-8 lg:w-10 xl:w-16 mr-4 transform hover:scale-110 transition-all ease-in-out" src={link.imageUrl} alt="" /></a>
                    })
                }
            </div>
        </footer>
    );
}

export default Footer;