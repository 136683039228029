import Web3 from 'web3'
import config from '../config';

const getWeb3NoAccount = () => {
  const httpProvider = new Web3.providers.HttpProvider(config.web3Provider, { timeout: 10000 })
  const web3NoAccount = new Web3(httpProvider)
  return web3NoAccount
}

export { getWeb3NoAccount }
