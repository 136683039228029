import React from 'react';
import './GfBubble.css'
import gfBubble1 from '../../../../assets/img/gfs/1.png'
import gfBubble2 from '../../../../assets/img/gfs/2.png'
import gfBubble3 from '../../../../assets/img/gfs/3.png'
import gfBubble4 from '../../../../assets/img/gfs/4.png'
import gfBubble5 from '../../../../assets/img/gfs/5.png'

function GfBubbleAnimation(props) {
    return (
        <div className="absolute top-0 left-0 w-screen h-screen z-0">
            <div className="gf-bubble gf-bubble-x1">
                <img className="w-full" src={gfBubble1} alt="" />
            </div>
            <div className="gf-bubble gf-bubble-x2">
                <img className="w-full" src={gfBubble2} alt="" />
            </div>
            <div className="gf-bubble gf-bubble-x3">
                <img className="w-full" src={gfBubble3} alt="" />
            </div>
            <div className="gf-bubble gf-bubble-x4">
                <img className="w-full" src={gfBubble4} alt="" />
            </div>
            {/* <div class="gf-bubble gf-bubble-x5">
                <img className="w-full" src={gfBubble5} alt="" />
            </div> */}
            {/* <div class="gf-bubble gf-bubble-x6">
                <img className="w-full" src={gfBubble1} alt="" />
            </div> */}
            {/* <div class="gf-bubble gf-bubble-x7">
                <img className="w-full" src={gfBubble2} alt="" />
            </div> */}
            <div className="gf-bubble gf-bubble-x8">
                <img className="w-full" src={gfBubble3} alt="" />
            </div>
            <div className="gf-bubble gf-bubble-x9">
                <img className="w-full" src={gfBubble4} alt="" />
            </div>
            <div className="gf-bubble gf-bubble-x10">
                <img className="w-full" src={gfBubble5} alt="" />
            </div>
        </div>
    );
}

export default GfBubbleAnimation;