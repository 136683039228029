import { motion } from 'framer-motion';
import React from 'react';
import { useInView } from 'react-intersection-observer';

function StageOdd({ imageUrl, stageName, percent, content }) {
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '-100px 0px',
    });
    return (
        <div ref={ref} className="my-5 md:my-10 xl:my-20 flex w-4/6 md:w-3/6 xl:w-4/6">
            <motion.img 
                initial={{ opacity: 0, scale: 0.7, translateY: 100, rotate: '0deg' }}
                animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0.7, translateY: inView ? 0 : 100, rotate: inView ? "-12deg" : "0deg" }}
                className="roadmap-gf-spinR hidden xl:block w-60 h-60 transform -rotate-12 mr-16 rounded-2xl border-8 border-white border-solid" 
                src={imageUrl} 
                alt=""
            />
            <motion.div
                initial={{ opacity: 0, translateY: 100, }}
                animate={{ opacity: inView ? 1 : 0,translateY: inView ? 0 : 100}}
            >
                <h2 className="font-coiny text-2xl md:text-3xl xl:text-4xl mb-3">{stageName}</h2>
                <p className="font-nunito text-lg md:text-2xl xl:text-3xl font-bold">
                    {content}
                </p>
            </motion.div>
        </div>
    );
}

export default StageOdd;