import React, { useState } from 'react';
import FaqSection from './FaqSection';
import HeroSection from './HeroSection/index';
import MintSection from './MintSection/MintSection';
import RoadmapSection from './RoadmapSection/Index';
import WhatIsCryptoGFSection from './WhatIsCryptoGFSection';
import WhoAreWeSection from './WhoAreWeSection';
import Footer from '../../common/Footer'
import Navbar from '../../common/Navbar';
import ConnectModal from '../../common/ConnectModal';

function HomePage(props) {
  let [isModalOpen,setIsModalOpen]=useState(false)
  let [currentSection,setCurrentSection]=useState(null)
  return (
      <>
        <Navbar currentSection={currentSection}/>
        <HeroSection />
        <MintSection 
          setCurrentSection={setCurrentSection}
          setIsModalOpen={setIsModalOpen}
        />  
        <WhatIsCryptoGFSection />
        <RoadmapSection setCurrentSection={setCurrentSection} />
        <WhoAreWeSection setCurrentSection={setCurrentSection} />
        <FaqSection setCurrentSection={setCurrentSection} />
        <Footer />
        {isModalOpen && <ConnectModal setIsModalOpen={setIsModalOpen} />}
      </>
  );
}

export default HomePage;