import React, { useState } from 'react'
import gf1 from '../../../assets/img/who-gf-1.png'
import gf2 from '../../../assets/img/who-gf-2.png'
import gf3 from '../../../assets/img/who-gf-3.png'
import gf4 from '../../../assets/img/who-gf-4.png'
import gf5 from '../../../assets/img/who-gf-5.png'
import gf6 from '../../../assets/img/who-gf-6.png'
import ReactCardFlip from 'react-card-flip'
import InView from 'react-intersection-observer'
// import softGradientCicle from "../../../assets/img/soft-gradient-circle.svg";

let MemberCard = ({ member }) => {
  let [isFlipped, setIsFlipped] = useState(false)
  return (
    <div
      onMouseEnter={() => {
        setIsFlipped(true)
      }}
      onMouseLeave={() => {
        setIsFlipped(false)
      }}
      className="w-28 md:w-32 lg:w-40 xl:w-64 m-3 xl:m-5 flex flex-col items-center"
    >
      <ReactCardFlip isFlipped={isFlipped}>
        <img className="rounded-full" src={member.dp} alt="" />
        <img className="rounded-full" src={member.dp} alt="" />
      </ReactCardFlip>
      {/* <p onClick={()=>{setIsFlipped(!isFlipped)}} className="text-sm xl:text-2xl underline font-bold cursor-pointer">{isFlipped? "Go Back":"Show More"}</p> */}
      <h2 className="font-coiny my-1 xl:my-4 md:text-2xl xl:text-4xl text-center">
        {member.name}
      </h2>
      <p className="font-nunito text-center md:text-sm xl:text-3xl font-bold text-cg-pink-normal">
        {member.designation}
      </p>
    </div>
  )
}

function WhoAreWeSection({ setCurrentSection }) {
  let handeleInview = (inView, entry) => {
    if (inView) setCurrentSection('team')
    // else setCurrentSection(null)
  }
  let teamMembers = [
    {
      dp: gf1,
      name: 'Juliet Star',
      designation: 'Founder',
      link: 'https://twitter.com/CryptoGrlfriend',
    },
    {
      dp: gf2,
      name: 'Dreamer',
      designation: 'Community Manager',
      link: 'https://twitter.com/JoseTorrao1',
    },
    {
      dp: gf3,
      name: 'Andayrue',
      designation: 'Creative Writer',
    },
    {
      dp: gf6,
      name: 'Daniel',
      designation: 'Lead Developer',
    },
    {
      dp: gf5,
      name: 'Alex',
      designation: 'Developer',
    },
    {
      dp: gf4,
      name: 'Crusher',
      designation: 'Advisor',
      link: 'https://twitter.com/CrusherApe',
    },
  ]
  return (
    <InView onChange={handeleInview}>
      <section
        id="team"
        className="-mt-8 pb-12 pt-12 xl:pt-28 flex flex-col items-center who-are-we-section"
      >
        <h1 className="my-8 font-coiny whitespace-nowrap text-2xl md:text-4xl xl:text-6xl blue-stroke-text ">
          WHO ARE WE?
        </h1>
        <div className="flex flex-wrap justify-center w-6/12">
          {teamMembers.map((member, id) => {
            return (
              <a href={member.link} target="_blank" rel="noreferrer">
                <MemberCard member={member} key={id} />
              </a>
            )
          })}
        </div>
        {/* <img style={{ zIndex: -10 }} className="absolute hidden -bottom-20 -right-96 sm:block" src={softGradientCicle} alt="" />
                <img style={{ zIndex: -10 }} className="absolute hidden -top-40 -left-96 sm:block" src={softGradientCicle} alt="" /> */}
      </section>
    </InView>
  )
}

export default WhoAreWeSection
