import NotFound from './components/common/NotFound';
import { Route, Routes } from 'react-router';
import HomePage from './components/pages/Home';
import ComingSoon from './components/common/ComingSoon/ComingSoon';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { RefreshContextProvider } from "./context/RefreshContext";

function App() {
  return (
    <RefreshContextProvider>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/marketplace" element={<ComingSoon />} />
      </Routes>
      <NotificationContainer />
    </RefreshContextProvider>
  );
}

export default App;
