import React from 'react'
import StageOdd from './StageOdd'
import StageEven from './StageEven'
import gf1 from '../../../../assets/img/gfs/2.png'
import gf2 from '../../../../assets/img/gfs/4.png'
import gf3 from '../../../../assets/img/gfs/3.png'
import gf4 from '../../../../assets/img/gfs/6.png'
import gf5 from '../../../../assets/img/gfs/5.png'
import gf6 from '../../../../assets/img/gfs/3.png'
import InView from 'react-intersection-observer'

function RoadmapSection({ setCurrentSection }) {
  let handeleInview = (inView, entry) => {
    if (inView) setCurrentSection('roadmap')
    // else setCurrentSection(null)
  }

  let stages = [
    {
      name: 'CryptoGirlfriend NFTs - Q1',
      content:
        '2 Rounds of Pre-Sale Minting will occur before the big public launch date at the price of 0.3 BNB and 0.4 BNB.',
      imageUrl: gf1,
    },
    {
      name: 'NFT Marketplace - Q1',
      content:
        'A truly marketplace for BSC, FTM and other chains with built in rarity service, launchpad and marketing for NFT projects. CGF NFT holders will earn fee rewards from the marketplace.',
      imageUrl: gf2,
    },
    {
      name: 'NFT Project Launchpad & AMAs - Q1',
      content:
        'CryptoGirlfriend is an experienced AMA Host and Crypto Influencer with a large following on Twitter and YouTube. She will be conducting ongoing AMAs on her various channels in Text, Audio and Video format to help promote launchpad partner projects on the NFT Marketplace.',
      imageUrl: gf3,
    },
    {
      name: 'CryptoGirlfriend Merchandise - Q1',
      content:
        'Member-Exclusive CryptoGirlfriend Merch Store gets unlocked, featuring Limited Edition tees, hoodies, and other goodies for NFT holders.',
      imageUrl: gf4,
    },
    {
      name: 'CryptoGirlfriend Comic Book NFT & printed Comic Book - Q2',
      content:
        'The CryptoGirlfriend Comic Book Series is Established. CryptoGirlfriend NFT Holders will have exclusive access to the first CryptoGirlfriend NFT digital comic book and receive a printed comic book too!',
      imageUrl: gf5,
    },
    {
      name: 'Stake to Earn Games - Q2',
      content:
        'After all of the CryptoGirlfriend NFTs have been minted, we will be launching stake to earn games. These games are being developed to stake your CryptoGirlfriends to earn other collectibles.',
      imageUrl: gf6,
    },
  ]
  return (
    <InView onChange={handeleInview}>
      <section
        id="roadmap"
        className="relative roadmap-section flex flex-col items-center justify-center"
      >
        <h1 className="absolute top-6 md:top-0 left-2/4 font-coiny whitespace-nowrap text-2xl md:text-4xl xl:text-6xl blue-stroke-text transform -translate-x-1/2 -rotate-3 uppercase">
          Our Roadmap
        </h1>
        <div
          style={{
            background:
              'linear-gradient(rgba(243, 238, 252, 0.02) 0%, rgb(243, 238, 252,0.5) 28.67%, rgba(243, 238, 252, 0.5) 77.42%, rgba(243, 238, 252, 0) 100%)',
          }}
          className="my-10 mt-36 max-w-screen-xl flex flex-col items-center"
        >
          {stages.map((stage, id) => {
            if (id % 2 === 0) {
              return (
                <StageEven
                  key={id}
                  imageUrl={stage.imageUrl}
                  stageName={stage.name}
                  percent={stage.percent}
                  content={stage.content}
                />
              )
            } else {
              return (
                <StageOdd
                  key={id}
                  imageUrl={stage.imageUrl}
                  stageName={stage.name}
                  percent={stage.percent}
                  content={stage.content}
                />
              )
            }
          })}
        </div>
      </section>
    </InView>
  )
}

export default RoadmapSection
