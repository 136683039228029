import { getWeb3NoAccount } from './web3';
import nftContractAbi from '../config/abis/cryptoGirlFriend.json';
import contracts from '../config/constants/contracts';

const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? getWeb3NoAccount();
  return new _web3.eth.Contract(abi, address);
};

export const getCryptoGirlFriendContract = (web3, chainId) => {
  return getContract(nftContractAbi, contracts.cryptoGirlFriend[chainId], web3);
};
