import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/nav-logo.png'

function NotFound(props) {
    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-cg-pink-light">
            <img className="w-72" src={logo} alt="" />
            <h1 className="mt-6 font-nunito text-xl uppercase text-cg-pink-normal font-bold">Page Not Found</h1>
            <Link className="underline font-bold" to="/" >Go to Homepage</Link>
        </div>
    );
}

export default NotFound;