import React from 'react';
import gf1 from '../../../assets/img/gfs/6.png'
import gf2 from '../../../assets/img/gfs/4.png'
import gf3 from '../../../assets/img/gfs/3.png'
import leftStar from '../../../assets/img/what-gf-stars-left.svg'
import rightStar from '../../../assets/img/what-gf-stars-right.svg'

function WhatIsCryptoGFSection(props) {
    return (
        <section id="story" className="mt-11 pb-20 flex flex-col items-center what-is-gf-section p-10 overflow-hidden">
            <div className="mt-36 w-full max-w-3xl flex flex-col items-center">
                <h1 className="text-4xl xl:text-5xl font-coiny pink-stroke-text text-center">Our Story</h1>
                <p className="mt-6 text-sm md:text-xl xl:text-2xl text-black font-nunito font-bold text-center">
                The idea behind CryptoGirlfriend came from the notion that in a bull market everyone gets girlfriends. Well now everyone can have their very own CryptoGirlfriend! Just simply mint your very own beautiful CryptoGirlfriend right here on cryptogirlfriend.art.
                </p>
                <p  className="mt-6 text-sm md:text-xl xl:text-2xl text-black font-nunito font-bold text-center"> 
                The CryptoGirlfriend NFT project is a collection of 1269 uniquely designed beautiful CryptoGirlfriends residing on the Binance Smart Chain. These art pieces were handpicked by CryptoGirlfriend herself and is the very first piece of the CryptoGirlfriend NFT project which will later include a CryptoGirlfriend Comic Book. 
                </p>
                <p  className="mt-6 text-sm md:text-xl xl:text-2xl text-black font-nunito font-bold text-center">
                After all of the CryptoGirlfriend NFTs have been minted, we will be launching stake to earn games. These games are being developed to stake your CryptoGirlfriends to earn other collectibles. 
                </p>
                <p  className="mt-6 text-sm md:text-xl xl:text-2xl text-black font-nunito font-bold text-center">
                The next part of the project will include an NFT rarity service for other NFT projects. CryptoGirlfriend will also be a marketing resource for other NFT projects by conducting AMAs via Telegram, YouTube and Twitter Spaces.
                </p>
                <p  className="mt-6 text-sm md:text-xl xl:text-2xl text-black font-nunito font-bold text-center">
                The CryptoGirlfriend NFT Project is an idea that has been in the works since early 2021. Juliet Star has always been a fan of anime and comic books. She wanted to do something different and continue to evolve the CryptoGirlfriend character. NFTs are becoming very popular but their utility is still being underutilized. This is how the CryptoGirlfriend comic book vision came about. The CryptoGirlfriend comic book will not only be an NFT, but physical copies will also be printed and available to only CryptoGirlfriend NFT holders.
                </p>
            </div>
            <div className="relative mt-32 w-full max-w-3xl flex justify-center box-border">
                <img className="w-1/3 box-border rounded-xl" src={gf1} alt="" />
                <img className="w-1/3 mx-5 transform -translate-y-10 box-border rounded-xl" src={gf2} alt="" />
                <img className="w-1/3 box-border rounded-xl" src={gf3} alt="" />
                <img className="absolute hidden md:block -bottom-16 -left-20 w-20" src={leftStar} alt="" />
                <img className="absolute hidden md:block -bottom-16 -right-20 w-20" src={rightStar} alt="" />
            </div>
        </section>
    );
}

export default WhatIsCryptoGFSection;