import React from 'react'
import GfBubbleAnimation from './GfBubbleAnimation'
import footerDiscord from '../../../assets/img/footer-discord.svg'
import footerInsta from '../../../assets/img/footer-Insta.svg'
// import footerOpensea from'../../../assets/img/footer-opensea.svg'
import footerTwitter from '../../../assets/img/footer-twitter.svg'
import footerYoutube from '../../../assets/img/footer-youtube.svg'
import logo from '../../../assets/img/nav-logo.png'
import Navbar from '../Navbar'

function index(props) {
  let socialLinks = [
    { imageUrl: footerDiscord, link: 'https://discord.gg/yJfVrrQ9qn' },
    { imageUrl: footerTwitter, link: 'https://twitter.com/CryptoGrlfriend' },
    {
      imageUrl: footerInsta,
      link: 'https://www.instagram.com/cryptogrlfriend/',
    },
    // { imageUrl: footerOpensea, link: "https://opensea.io/collection/cryptogirlfriend" },
    {
      imageUrl: footerYoutube,
      link: 'https://www.youtube.com/c/CryptoGirlfriend',
    },
  ]
  return (
    <section className="relative flex flex-col items-center w-screen min-h-screen hero-section overflow-hidden">
      <Navbar />
      <img className="w-96 mt-20 p-5" src={logo} alt="" />
      <div style={{ borderRadius: 33 }} className="mt-10 bg-white p-3">
        <div
          style={{ borderRadius: 33 }}
          className="p-6 xl:p-7 border-2 border-dashed border-cg-pink-normal"
        >
          <p className="font-coiny  text-lg xl:text-5xl text-cg-pink-normal uppercase">
            coming soon
          </p>
        </div>
      </div>
      <div className="flex mt-10">
        {socialLinks.map((link, id) => {
          return (
            <a key={id} href={link.link} target="_blank" rel="noreferrer">
              <img
                className="w-8 mx-3 cursor-pointer"
                src={link.imageUrl}
                alt=""
              />
            </a>
          )
        })}
      </div>
      <GfBubbleAnimation />
    </section>
  )
}

export default index
