import React, { useState, useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import BigNumber from 'bignumber.js'
import MintCountDown from './MintCountDown'
import softGradientCicle from '../../../../assets/img/soft-gradient-circle.svg'
import InView from 'react-intersection-observer'

import { useWeb3React } from '@web3-react/core'
import {
  useTotalSupply,
  useGlobalData,
  useMint,
} from '../../../../hooks/useCGFData'
import { useEthBalance } from '../../../../hooks/useBalance'
import { bnToDec } from '../../../../utils/utils'

function MintSection({ setIsModalOpen, setCurrentSection }) {
  const ethBalance = useEthBalance()
  const { totalSupply } = useTotalSupply()
  const { mintPrice, maxToMint, saleIsActive } = useGlobalData()
  const { onMint, pending } = useMint(mintPrice)

  const [amount, setAmount] = useState(1)

  const handleDecrement = () => {
    if (amount > 0) {
      setAmount(amount - 1)
    }
  }

  const handleIncrement = () => {
    if (amount < maxToMint) {
      setAmount(amount + 1)
    }
  }

  const handeleInview = (inView, entry) => {
    if (inView) setCurrentSection('mint')
    // else setCurrentSection(null)
  }

  const handleMint = async () => {
    const result = await onMint(amount)
    if (result) {
      NotificationManager.success('Minted CGF Token Successfully!')
    }
  }

  const mintEnabled =
    saleIsActive &&
    !pending &&
    new BigNumber(mintPrice).times(amount).lte(ethBalance)

  const { account /*, deactivate*/ } = useWeb3React()

  useEffect(() => {
    if (account) {
      setIsModalOpen(false)
    }
  }, [account, setIsModalOpen])

  // const disconnectWallet = () => {
  //   deactivate();
  // };

  return (
    <InView onChange={handeleInview}>
      <section
        id="mint"
        className="pt-20 relative flex flex-col items-center w-full overflow-hidden box-border"
      >
        <p className="mb-2 xl:mb-4 font-coiny blue-stroke-text text-3xl xl:text-6xl uppercase">
          Mint Girlfriend
        </p>
        <p className="font-nunito text-lg xl:text-3xl font-bold">
          1269 Crypto Girlfriend NFT
        </p>
        <p className="mb-2 xl:mb-4 font-nunito text-lg xl:text-3xl font-bold">
          (0.2 BNB/Girlfriend)
        </p>
        <MintCountDown />
        <div className="mt-4 p-0 md:p-4 xl:p-7 flex flex-col md:flex-row justify-between w-4/5 max-w-3xl xl:max-w-5xl border-b-2 xl:border-b-4 border-dashed border-cg-pink-medium">
          <div className="pl-4 pb-4 md:p-0 flex items-center justify-between md:justify-start border-b-2 border-dashed border-cg-pink-medium md:border-none mb-4 md:m-0">
            <p className="font-coiny blue-stroke-text-small text-base md:text-xl xl:text-3xl mr-4 xl:mr-8">
              Wallet address
            </p>
            <p className="text-cg-pink-normal text-base md:text-xl xl:text-3xl font-nunito font-bold">
              {account && `${account.slice(0, 4)}...${account.slice(-4)}`}
            </p>
          </div>
          <div className="pl-4 pb-4 md:p-0 flex justify-between md:justify-star">
            <p className="font-coiny blue-stroke-text-small text-base md:text-xl xl:text-3xl mr-4 xl:mr-8">
              My BNB Balance
            </p>
            <p className="font-coiny blue-stroke-text-small text-base md:text-xl xl:text-3xl">
              {bnToDec(ethBalance).toFixed(3)} BNB
            </p>
          </div>
        </div>
        <div className="p-4 xl:p-7 flex items-center justify-between w-4/5 max-w-3xl xl:max-w-5xl border-b-2 xl:border-b-4 border-dashed border-cg-pink-medium">
          <p className="my-auto font-coiny blue-stroke-text-small text-base md:text-xl xl:text-3xl mr-8">
            Amount
          </p>
          <div className="flex items-center">
            <button
              className="px-3 font-coiny blue-stroke-text-xsmall text-base md:text-xl xl:text-3xl font-bold"
              onClick={handleDecrement}
            >
              -
            </button>
            <p className="font-coiny blue-stroke-text-xsmall text-base md:text-xl xl:text-3xl font-bold">
              {amount}
            </p>
            <button
              className="px-3 font-coiny blue-stroke-text-xsmall text-base md:text-xl xl:text-3xl font-bold"
              onClick={handleIncrement}
            >
              +
            </button>
            <button
              onClick={() => {
                setAmount(maxToMint)
              }}
              className="font-coiny ml-2 xl:ml-7 p-1 px-3 xl:p-3 xl:px-8 text-cg-pink-normal hover:text-white hover:bg-cg-pink-normal text-sm xl:text-3xl border-2 xl:border-4 border-cg-pink-normal rounded-3xl cursor-pointer transition-all ease-in-out duration-200"
            >
              MAX
            </button>
          </div>
        </div>
        <div className="p-4 xl:p-7 flex items-center justify-between w-4/5 max-w-3xl xl:max-w-5xl">
          <p className="font-coiny blue-stroke-text-small text-xl xl:text-3xl">
            Total Price
          </p>
          <p className="font-coiny blue-stroke-text-small text-xl xl:text-3xl">
            {new BigNumber(mintPrice)
              .times(amount)
              .div(new BigNumber(1e18))
              .toNumber()}{' '}
            BNB
          </p>
        </div>
        <div className="p-4 xl:p-7 hidden md:flex items-center justify-between  w-4/5 max-w-3xl xl:max-w-5xl">
          <p className="font-coiny pink-stroke-text-small text-base md:text-xl xl:text-2xl">
            Progress
          </p>
          <div className="mx-12 h-2 xl:h-3 w-full bg-cg-pink-medium rounded-xl overflow-hidden">
            <div
              className="h-full bg-cg-pink-normal"
              style={{ width: `${(totalSupply / 1269) * 100}%` }}
            ></div>
          </div>
          <p className="font-nunito text-cg-pink-normal text-base md:text-xl xl:text-2xl">
            {totalSupply}/1269
          </p>
        </div>
        <div className="p-4 xl:p-7 flex flex-col md:hidden w-4/5 max-w-3xl xl:max-w-5xl">
          <p className="font-coiny pink-stroke-text-small text-base md:text-xl xl:text-2xl">
            Progress
          </p>
          <div className="flex items-center">
            <div className="mr-3 h-2 xl:h-3 w-full bg-cg-pink-medium rounded-xl overflow-hidden">
              <div
                className="h-full bg-cg-pink-normal"
                style={{ width: `${(totalSupply / 1269) * 100}%` }}
              ></div>
            </div>
            <p className="font-nunito text-cg-pink-normal text-base md:text-xl xl:text-2xl">
              {totalSupply}/1269
            </p>
          </div>
        </div>
        <button
          onClick={() => {
            if (!account) {
              setIsModalOpen(true)
            } else {
              handleMint()
            }
          }}
          className="w-3/5 md:w-max mt-4 hover:scale-150 transition-all ease-in-out duration-200 rounded-3xl p-3 px-5 text-white font-nunito font-medium text-xl"
          style={{
            cursor: mintEnabled ? 'pointer' : 'not-allowed',
            backgroundColor: mintEnabled ? '#ea5d63' : '#737a89',
          }}
          disabled={!mintEnabled}
        >
          {account ? 'Mint Your Girlfriend' : 'Connect Wallet'}
        </button>
        {/* <p className="font-nunito text-cg-pink-normal text-base md:text-xl xl:text-2xl mt-5 text-center mx-10">
          {totalSupply} CGFs have been minted by presale.
        </p>
        <p className="font-nunito text-cg-pink-normal text-base md:text-xl xl:text-2xl text-center mx-10">
          You can use secondary marketplaces to purchase CGFs,{' '}
          <a
            className="font-bold underline"
            href="https://element.market/collections/crypto-girlfriend-f78fdb"
          >
            ELEMENT Marketplace
          </a>
          ,{' '}
          <a
            className="font-bold underline"
            href="https://tofunft.com/collection/crypto-girlfriend/items"
          >
            TOFU Marketplace
          </a>
        </p> */}
        <img
          style={{ zIndex: -10 }}
          className="absolute hidden -bottom-20 -right-96 sm:block"
          src={softGradientCicle}
          alt=""
        />
        <img
          style={{ zIndex: -10 }}
          className="absolute hidden -top-40 -left-96 sm:block"
          src={softGradientCicle}
          alt=""
        />
      </section>
    </InView>
  )
}

export default MintSection
