import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import logo from '../../assets/img/nav-logo.png'
import hamOpenIcon from '../../assets/img/ham-menu-open.svg'
import hamCloseIcon from '../../assets/img/ham-menu-closed.svg'
import footerDiscord from '../../assets/img/footer-discord.svg'
import footerInsta from '../../assets/img/footer-Insta.svg'
import footerTelegram from '../../assets/img/footer-telegram.svg'
// import footerOpensea from'../../assets/img/footer-opensea.svg'
import footerTwitter from '../../assets/img/footer-twitter.svg'
import footerYoutube from '../../assets/img/footer-youtube.svg'


let NavLink = ({ name, isCurrentSection, className }) => {
  return <li
    className={`mx-2 md:mx-3 xl:mx-10 font-coiny md:text-sm lg:text-lg xl:text-2xl ${isCurrentSection ? 'blue-stroke-text' : 'text-white'} ${className}`}>
    {name}
  </li>
}



function Navbar({ currentSection }) {
  let [isHamOpen, setIsHamOpen] = useState(false)
  // const [section2Ref, section2InView] = useInView({ threshold: 0.5 });
  const wrapperRef = useRef(null);
  let navLinks = [
    {
      name: "Mint",
      color: "#000000",
      scrollTo: "/#mint",
    },
    {
      name: "Our Story",
      color: "#000000",
      scrollTo: "/#story",
    },
    {
      name: "Roadmap",
      color: "#000000",
      scrollTo: "/#roadmap",
    },
    {
      name: "Team",
      color: "#000000",
      scrollTo: "/#team",
    },
    {
      name: "FAQ",
      color: "#000000",
      scrollTo: "/#faq",
    },
    {
      name: "Marketplace",
      color: "#000000",
    },

  ]
  let socialLinks = [
    { imageUrl: footerDiscord, link: "https://discord.gg/yJfVrrQ9qn" },
    { imageUrl: footerTelegram, link: "https://t.me/cryptogirlfriend" },
    { imageUrl: footerTwitter, link: "https://twitter.com/CryptoGrlfriend" },
    { imageUrl: footerInsta, link: "https://www.instagram.com/cryptogrlfriend/" },
    // { imageUrl: footerOpensea, link: "https://opensea.io/collection/cryptogirlfriend" },
    { imageUrl: footerYoutube, link: "https://www.youtube.com/c/CryptoGirlfriend" },
  ]

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsHamOpen(false)
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <nav
      style={{ background: 'linear-gradient(90.01deg, #3E0014 0.01%, #FA2469 97.68%)' }}
      className="fixed left-0 top-0 w-screen flex items-center justify-between p-5 pr-10 z-40"
    >
      <img className="w-36 md:w-64 xl:max-w-screen-md" src={logo} alt="" />
      <div>
        <ul className="hidden md:flex">
          {
            navLinks.map((link, id) => {
              if (link.scrollTo) {
                return <NavHashLink
                  key={id}
                  to={link.scrollTo}
                  smooth
                ><NavLink name={link.name} isCurrentSection={`/#${currentSection}` === link.scrollTo} /></NavHashLink>
              } else {
                return <a href ="https://collectioncapitol.com" target="_blank"><NavLink name={link.name} color={link.color} /></a>
              }
            })
          }
        </ul>
        <button onClick={() => { setIsHamOpen(!isHamOpen) }} className="block md:hidden cursor-pointer">
          {
            !isHamOpen ?
              <img src={hamOpenIcon} alt="" />
              :
              <img src={hamCloseIcon} alt="" />
          }
        </button>
        {
          isHamOpen &&
          <div ref={wrapperRef} style={{ background: 'linear-gradient(180deg, #430116 0%, #BE194E 56.41%)' }} className="p-7 fixed h-screen right-0 top-0 w-1/2 z-50">
            <button onClick={() => { setIsHamOpen(!isHamOpen) }} className="ml-auto block md:hidden cursor-pointer">
              {
                !isHamOpen ?
                  <img src={hamOpenIcon} alt="" />
                  :
                  <img src={hamCloseIcon} alt="" />
              }
            </button>
            <ul className="flex flex-col">
              {
                navLinks.map((link, id) => {
                  if (link.scrollTo) {
                    return <NavHashLink
                      key={id}
                      to={link.scrollTo}
                      smooth
                    ><NavLink className="my-2" name={link.name} color={link.color} /></NavHashLink>
                  } else {
                    return <a href="https://collectioncapitol.com" target="_blank"><NavLink className="my-2" name={link.name} color={link.color} /></a>
                  }
                })
              }
            </ul>
            <div className="mt-4">
              {
                socialLinks.map((link, id) => {
                  return <a href={link.link} target="_blank" rel="noreferrer"><img key={id} className="w-8 mb-3 cursor-pointer transform hover:scale-110 transition-all ease-in-out" src={link.imageUrl} alt="" /></a>
                })
              }
            </div>
          </div>
        }
      </div>
    </nav>
  );
}

export default Navbar;