import React from 'react'
import Countdown from 'react-countdown'

function MintCountDown(props) {
  const countDownRenderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="p-2 xl:p-5 my-3 xl:my-5 max-w-64 xl:max-w-96 flex justify-center border-dashed border-4 border-cg-pink-medium rounded-xl bg-cg-pink-light">
        <div className="flex flex-col items-center mx-3">
          <p className="text-cg-pink-normal font-nunito text-2xl xl:text-4xl font-black">
            {days}
          </p>
          <p className="text-black text-sm xl:text-base font-bold">Days</p>
        </div>
        <div className="flex flex-col items-center mx-3 ">
          <p className="text-cg-pink-normal font-nunito text-2xl xl:text-4xl font-black">
            {hours}
          </p>
          <p className="text-black text-sm xl:text-base font-bold">Hours</p>
        </div>
        <div className="flex flex-col items-center mx-3 ">
          <p className="text-cg-pink-normal font-nunito text-2xl xl:text-4xl font-black">
            {minutes}
          </p>
          <p className="text-black text-sm xl:text-base font-bold">Minutes</p>
        </div>
        <div className="flex flex-col items-center mx-3 ">
          <p className="text-cg-pink-normal font-nunito text-2xl xl:text-4xl font-black">
            {seconds}
          </p>
          <p className="text-black text-sm xl:text-base font-bold">Seconds</p>
        </div>
      </div>
    )
  }

  return (
    <Countdown
      date={new Date('2022-05-02T17:00:00+00:00')}
      renderer={countDownRenderer}
    />
  )
}

export default MintCountDown
